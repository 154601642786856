import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../constants';


const initialState = {
    user: null,
    error: null,
    loading: false
};

export const getUser = createAsyncThunk('user/getUser', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/details', {}, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const updateUser = createAsyncThunk('user/updateUser', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/edit/details', { name: data.name, phone: data.phone, city: data.city, state: data.state, country: data.country, address: data.address }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const updateUserNameAndPhone = createAsyncThunk('user/updateUserNameAndPhone', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/edit/nameAndPhone', { name: data.name, phone: data.phone }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const updatePhoto = createAsyncThunk('user/updatePhoto', async (data, { rejectWithValue, getState }) => {
    try {
        const formData = new FormData();
        formData.append('photo', data.photo);

        const response = await axios.post(API_URL + '/user/edit/photo', formData, {
            headers: {
                Authorization: `Bearer ${data.token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.user = null;
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.user = null;
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(updateUserNameAndPhone.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateUserNameAndPhone.fulfilled, (state, action) => {
                state.user = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(updateUserNameAndPhone.rejected, (state, action) => {
                state.user = null;
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(updatePhoto.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePhoto.fulfilled, (state, action) => {
                state.user = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(updatePhoto.rejected, (state, action) => {
                state.user = null;
                state.error = action.payload;
                state.loading = false;
            })
    }
});

export default userSlice.reducer;
