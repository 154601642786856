import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../constants";

const initialState = {
    social: [],
    education: [],
    workExperience: [],
    error: null,
    loading: false
};

export const addSocialLink = createAsyncThunk('account/addSocialLink', async (data, { rejectWithValue }) => {
    console.log(data);
    try {
        const response = await axios.post(API_URL + '/user/add/social', { linkType: data.linkType, link: data.link }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getSocialLinks = createAsyncThunk('account/getSocialLinks', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/get/social', {}, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const updateSocialLink = createAsyncThunk('account/updateSocialLink', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/edit/social', { linkId: data.linkId, linkType: data.linkType, link: data.link }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteSocialLink = createAsyncThunk('account/deleteSocialLink', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/delete/social', { linkId: data.linkId }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getEducation = createAsyncThunk('account/getEducation', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/get/education', {}, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const addEducation = createAsyncThunk('account/addEducation', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/add/education', { institute: data.nameOfInstitute, degree: data.nameOfDegree, startYear: data.startYear, endYear: data.endYear }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const updateEducation = createAsyncThunk('account/updateEducation', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/edit/education', { educationId: data.educationId, institute: data.nameOfInstitute, degree: data.nameOfDegree, startYear: data.startYear, endYear: data.endYear }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteEducation = createAsyncThunk('account/deleteEducation', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/delete/education', { educationId: data.educationId }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getWorkExperience = createAsyncThunk('account/getWorkExperience', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/get/work', {}, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const addWorkExperience = createAsyncThunk('account/addWorkExperience', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/add/work', { company: data.companyName, position: data.position, startYear: data.startDate, endYear: data.endDate }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const updateWorkExperience = createAsyncThunk('account/updateWorkExperience', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/edit/work', { workId: data.workExperienceId, company: data.companyName, position: data.position, startYear: data.startDate, endYear: data.endDate }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteWorkExperience = createAsyncThunk('account/deleteWorkExperience', async (data, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/user/delete/work', { workId: data.workExperienceId }, {
            headers: {
                Authorization: `Bearer ${data.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(addSocialLink.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addSocialLink.fulfilled, (state, action) => {
                state.social = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(addSocialLink.rejected, (state, action) => {
                state.social = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(getSocialLinks.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSocialLinks.fulfilled, (state, action) => {
                state.social = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(getSocialLinks.rejected, (state, action) => {
                state.social = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(updateSocialLink.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateSocialLink.fulfilled, (state, action) => {
                state.social = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(updateSocialLink.rejected, (state, action) => {
                state.social = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(deleteSocialLink.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteSocialLink.fulfilled, (state, action) => {
                state.social = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(deleteSocialLink.rejected, (state, action) => {
                state.social = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(getEducation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getEducation.fulfilled, (state, action) => {
                state.education = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(getEducation.rejected, (state, action) => {
                state.education = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(addEducation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addEducation.fulfilled, (state, action) => {
                state.education = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(addEducation.rejected, (state, action) => {
                state.education = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(updateEducation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateEducation.fulfilled, (state, action) => {
                state.education = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(updateEducation.rejected, (state, action) => {
                state.education = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(deleteEducation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteEducation.fulfilled, (state, action) => {
                state.education = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(deleteEducation.rejected, (state, action) => {
                state.education = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(getWorkExperience.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getWorkExperience.fulfilled, (state, action) => {
                state.workExperience = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(getWorkExperience.rejected, (state, action) => {
                state.workExperience = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(addWorkExperience.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addWorkExperience.fulfilled, (state, action) => {
                state.workExperience = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(addWorkExperience.rejected, (state, action) => {
                state.workExperience = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(updateWorkExperience.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateWorkExperience.fulfilled, (state, action) => {
                state.workExperience = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(updateWorkExperience.rejected, (state, action) => {
                state.workExperience = [];
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(deleteWorkExperience.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteWorkExperience.fulfilled, (state, action) => {
                state.workExperience = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(deleteWorkExperience.rejected, (state, action) => {
                state.workExperience = [];
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export default accountSlice.reducer;
