import React from 'react'

const Messages = () => {
  return (
    <div>
      Messages
    </div>
  )
}

export default Messages
