import React from 'react';
import "../../styles/Progress.css";
import { FaCode, FaTasks, FaLaptopCode, FaReact } from "react-icons/fa";

const data = [
  {
    logo: <FaReact />,
    name: "React Task #01",
    date: "5 minutes ago",
    status: "Opened",
    color_class: "open_status",
    title: "To-do Task List",
    desc: "Create a to-do list using React, useEffect & useState. Use of props is mandatory.",
  },
  {
    logo: <FaReact />,
    name: "React Task #02",
    date: "25 minutes ago",
    status: "Todo",
    color_class: "todo_status",
    title: "To-do Task List",
    desc: "Create a to-do list using React, useEffect & useState. Use of props is mandatory.",
  },
  {
    logo: <FaReact />,
    name: "React Task #03",
    date: "4 hours ago",
    status: "In Review",
    color_class: "review_status",
    title: "To-do Task List",
    desc: "Create a to-do list using React, useEffect & useState. Use of props is mandatory.",
  },
  {
    logo: <FaReact />,
    name: "React Task #01",
    date: "5 minutes ago",
    status: "Opened",
    color_class: "open_status",
    title: "To-do Task List",
    desc: "Create a to-do list using React, useEffect & useState. Use of props is mandatory.",
  },
  {
    logo: <FaReact />,
    name: "React Task #02",
    date: "25 minutes ago",
    status: "Todo",
    color_class: "todo_status",
    title: "To-do Task List",
    desc: "Create a to-do list using React, useEffect & useState. Use of props is mandatory.",
  },
  {
    logo: <FaReact />,
    name: "React Task #03",
    date: "4 hours ago",
    status: "In Review",
    color_class: "review_status",
    title: "To-do Task List",
    desc: "Create a to-do list using React, useEffect & useState. Use of props is mandatory.",
  },
  {
    logo: <FaReact />,
    name: "React Task #01",
    date: "5 minutes ago",
    status: "Opened",
    color_class: "open_status",
    title: "To-do Task List",
    desc: "Create a to-do list using React, useEffect & useState. Use of props is mandatory.",
  },
  {
    logo: <FaReact />,
    name: "React Task #02",
    date: "25 minutes ago",
    status: "Todo",
    color_class: "todo_status",
    title: "To-do Task List",
    desc: "Create a to-do list using React, useEffect & useState. Use of props is mandatory.",
  },
  {
    logo: <FaReact />,
    name: "React Task #03",
    date: "4 hours ago",
    status: "In Review",
    color_class: "review_status",
    title: "To-do Task List",
    desc: "Create a to-do list using React, useEffect & useState. Use of props is mandatory.",
  },
];

const Progress = () => {
  return (
    <div className='container-fluid'>
      <div className='row filter_tasks_row'>
        <div className='col'>
          <div className='row filter_menu_row'>
            <div className='filter_menu_active'>
              <div className='tasks_logo_active'>
                <FaCode />
              </div>
              <div className='tasks_text_active'>
                Core Test
              </div>
            </div>

            <div className='filter_menu'>
              <div className='tasks_logo'>
                <FaTasks />
              </div>
              <div className='tasks_text'>
                Tasks
              </div>
            </div>

            <div className='filter_menu'>
              <div className='tasks_logo'>
                <FaLaptopCode />
              </div>
              <div className='tasks_text'>
                Projects
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
          <div className='row search_task_row'>
            <div className='search_div'>
              <div class="wrap">
                <div class="search">
                  <input type="text" class="searchTerm" placeholder="What are you looking for?" />
                  <button type="submit" class="searchButton">
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row progress_row'>
        {data.map((task) => (
          <div className='col-lg-4 task_card_outer'>
            <div className='row task_card'>
              <div className='task_heading_sec'>
                <div className='t_h_left'>
                  <div className='t_h_logo react_t_logo'>
                    {task.logo}
                  </div>
                  <div className='t_h_text'>
                    {task.name}
                  </div>
                </div>
                <div className='t_h_right'>
                  {task.date}
                </div>
              </div>
              <div className='task_labels_div'>
                <span className={"task_labels " + task.color_class}>{task.status}</span>
              </div>
              <div className='task_title_sec'>
                {task.title}
              </div>
              <div className='task_desc_sec'>
                {task.desc}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Progress
