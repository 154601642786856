import React, { useState } from 'react';
import "../styles/Navbar.css";
import { Link } from 'react-router-dom';
import { TiThMenu } from "react-icons/ti";
import { ImCross } from "react-icons/im";

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };
    return (
        <>
            <div className='container-fluid nav_container'>
                <div className='row d-flex justify-content-between align-items-center'>
                    <div className='col'>
                        <div className='row brand_div'>
                            <Link to="https://multiscaler.com" className='nav_links'>MULTISCALER</Link>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='row nav_items'>
                            <div className='col-2 nav_menu'>
                                <Link to="https://multiscaler.com" className='nav_links'>Home</Link>
                            </div>
                            <div className='col-2 nav_menu'>
                                <Link to="https://multiscaler.com" className='nav_links'>Courses</Link>
                            </div>
                            <div className='col-2 nav_menu'>
                                <Link to="https://multiscaler.com/aboutus" className='nav_links'>About</Link>
                            </div>
                            <div className='col-2 nav_menu'>
                                <Link to="https://multiscaler.com" className='nav_links'>Contact</Link>
                            </div>
                        </div>

                        {/* Mobile menu */}
                        {isMobileMenuOpen ? (
                            <div className='row d-flex justify-content-end'>
                                <div className='row mobile_menu'>
                                    <div className='col d-flex justify-content-end align-items-center'>
                                        <ImCross className='mobile_menu_icon' onClick={toggleMobileMenu} />
                                    </div>
                                </div>
                                <div className='row mobile_menu_items'>
                                    <div className='col'>
                                        <div className='row'>
                                            <Link to="https://multiscaler.com" className='nav_links'>Home</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='row'>
                                            <Link to="https://multiscaler.com/internships" className='nav_links'>Internships</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='row'>
                                            <Link to="https://multiscaler.com/aboutus" className='nav_links'>About</Link>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='row'>
                                            <Link to="https://multiscaler.com" className='nav_links'>Contact</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='row d-flex justify-content-end'>
                                <div className='row mobile_menu'>
                                    <div className='col d-flex justify-content-end align-items-center'>
                                        <TiThMenu className='mobile_menu_icon' onClick={toggleMobileMenu} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
