import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyOtp, clearError, wrongEmail } from '../redux/slices/authSlice';
import Navbar from './Navbar';

const OtpCheck = () => {
    const dispatch = useDispatch();
    const { otpId, error } = useSelector((state) => state.auth);

    const [otp, setOtp] = useState("");
    const [password, setPassword] = useState("");
    const [innerError, setInnerError] = useState("");

    const continueApply = () => {
        dispatch(clearError());
        setInnerError("");
        if (!password.trim()) {
            setInnerError("Please enter your password");
            return;
        }

        if (password.length < 8) {
            setInnerError("Password length should minimum of 8 characters");
            return;
        }

        dispatch(verifyOtp({ otpId, otp, password }));

        setPassword("");
        setOtp("");
    }

    return (
        <>
            <Navbar />
            <div className='container-fluid'>
                <div className='row d-flex justify-content-center align-items-center px-3'>
                    <div className='col-lg-4'>
                        <div className='row brand_div_login'>
                            VERIFY OTP
                        </div>
                        <div className='row login_text'>
                            Please enter the OTP sent to your email
                        </div>
                        <div className='row login_error'>
                            {error && error.error}
                            {innerError}
                        </div>

                        <div className='row'>
                            <div className='row email_label'>
                                Enter OTP
                            </div>
                            <div className='email_input'>
                                <input type="text" className='login_input' onChange={(e) => setOtp(e.target.value)} value={otp} />
                            </div>
                        </div>

                        <div className='row'>
                            <div className='row email_label'>
                                Enter Password
                            </div>
                            <div className='email_input'>
                                <input type="password" className='login_input' onChange={(e) => setPassword(e.target.value)} value={password} />
                            </div>
                        </div>

                        <div className='row'>
                            <button className='login_button' onClick={continueApply}>
                                Verify
                            </button>
                        </div>

                        <div className='row'>
                            <p className='signup_text'>Incorrect Email? <span to="/applynow" className='signup_link' onClick={() => dispatch(wrongEmail())}>Change Here</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OtpCheck
