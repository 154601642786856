import React from 'react'

const Career = () => {
  return (
    <div className='container-fluid'>
      Career
    </div>
  )
}

export default Career
