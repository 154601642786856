import React, { useState, useEffect } from 'react';
import { logout, uploadCv, checkCv } from '../redux/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './Navbar';

const UploadCv = () => {
    const dispatch = useDispatch();
    const { user, isVerified, error } = useSelector((state) => state.auth);

    const [fileData, setFileData] = useState(null);

    const submitForReview = () => {
        if (fileData) {
            dispatch(uploadCv(fileData));
        } else {
            console.log('No file selected');
        }
    };

    useEffect(() => {
        dispatch(checkCv());
    }, []);

    return (
        <>
            <Navbar />
            <div className='container-fluid'>
                <div className='row d-flex justify-content-center align-items-center px-3'>
                    <div className='col-lg-4'>
                        <div className='row brand_div_login'>
                            UPLOAD RESUME
                        </div>

                        <div className='row login_error'>
                            {error && (typeof error.error === 'string'
                                ? error.error
                                : error.error?.message)}
                        </div>

                        {isVerified === 'none' && (
                            <>
                                <div className='row login_heading'>
                                    Hi {user.name},
                                </div>
                                <div className='row login_text'>
                                    Please upload your resume here. We will review and get back to you soon.
                                </div>

                                <div className='row note_text_login'>
                                    Note: File size should be maximum of 5 MB and should be in .pdf format
                                </div>

                                <div className='row'>
                                    <div className='row email_label'>
                                        Upload Your Resume Here
                                    </div>
                                    <div className='email_input_file'>
                                        <input
                                            type="file"
                                            className='login_input'
                                            onChange={(e) => setFileData(e.target.files[0])}
                                        />
                                    </div>
                                </div>

                                <div className='row'>
                                    <button className='login_button' onClick={submitForReview}>
                                        Submit For Review
                                    </button>
                                </div>
                            </>
                        )}
                        {isVerified === false && (
                            <>
                                <div className='row login_heading'>
                                    Hi {user.name},
                                </div>
                                <div className='row login_text'>
                                    As soon as we review your resume, you will be notified. And you can check your progress here.
                                </div>
                            </>
                        )}
                        {isVerified === true && (
                            <>

                            </>
                        )}

                        <div className='row'>
                            <p className='signup_text'>
                                Not your account?
                                <span className='logout_link' onClick={() => dispatch(logout())}>Logout</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadCv;
