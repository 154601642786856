import React from 'react';
import { IoNotifications } from "react-icons/io5";
import { MdMessage } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UpperBar = () => {
    const location = useLocation();

    const {user} = useSelector((state) => state.auth);

    return (
        <>
            <div className='container-fluid'>
                <div className='row upperbar_row'>
                    <div className='col upperbar_left'>
                        {location.pathname === "/dashboard" && (
                            "Dashboard"
                        )}
                        {location.pathname === "/dashboard/career" && (
                            "Career"
                        )}
                        {location.pathname === "/dashboard/progress" && (
                            "Progress"
                        )}
                        {location.pathname === "/dashboard/messages" && (
                            "Messages"
                        )}
                        {location.pathname === "/dashboard/notification" && (
                            "Notification"
                        )}
                        {location.pathname === "/dashboard/account" && (
                            "Account"
                        )}
                        {location.pathname === "/dashboard/help" && (
                            "Help"
                        )}
                    </div>
                    <div className='col'>
                        <div className='row'>
                            <div className='col upperbar_right'>
                                <div className='notification_logo_div'>
                                    <MdMessage />
                                </div>
                                <div className='notification_logo_div'>
                                    <IoNotifications />
                                </div>
                                <div className='name_section_upper'>
                                    <div className='image_5845_div'>
                                        <img src="/blank_dp.png" className='image_5845' />
                                    </div>
                                    <div className='name_div_upper'>
                                        {user?.name}
                                    </div>
                                    <div className='dropdown_icon_upper'>
                                        <FaChevronDown />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpperBar
