import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { sendForgotOtp, verifyForgotOtp, resetPassword, clearError } from '../redux/slices/forgotPasswordSlice';
import Navbar from './Navbar';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const { error, stage1, forgotOtpSend, otpVerified, resetDone, otpId } = useSelector((state) => state.forgotPassword);

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [innerError, setInnerError] = useState('');

    const sendForgotOtpHandler = () => {
        dispatch(clearError());
        setInnerError('');
        if (!email) {
            setInnerError('Please enter your email');
            return;
        }
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setInnerError('Please enter a valid email address');
            return;
        }
        dispatch(sendForgotOtp({ email }));
        setEmail('');
    }

    const verifyForgotOtpHandler = () => {
        dispatch(verifyForgotOtp({ otpId, otp }));
        setOtp('');
    }

    const resetPasswordHandler = () => {
        dispatch(resetPassword({ otpId, password }));
        setPassword('');
        setConfirmPassword('');
    }

    return (
        <>
            <Navbar />
            <div className='container-fluid'>

                <div className='row d-flex justify-content-center align-items-center px-3'>
                    <div className='col-lg-4'>
                        <div className='row brand_div_login'>
                            FORGOT PASSWORD
                        </div>
                        <div className='row reset_success'>
                            {resetDone && 'Password reset successfully'}
                        </div>
                        <div className='row login_error'>
                            {error && error.error}
                            {innerError}
                        </div>

                        {stage1 && (
                            <>
                                <div className='row login_text'>
                                    Enter your email and we will send you an OTP to reset your password
                                </div>
                                <div className='row'>
                                    <div className='row email_label'>
                                        Email
                                    </div>
                                    <div className='email_input'>
                                        <input type="text" className='login_input' onChange={(e) => setEmail(e.target.value)} value={email} />
                                    </div>
                                </div>

                                <div className='row'>
                                    <button className='login_button' onClick={sendForgotOtpHandler}>
                                        Email OTP
                                    </button>
                                </div>
                            </>
                        )}

                        {forgotOtpSend && (
                            <>
                                <div className='row login_text'>
                                    Enter OTP sent to your email
                                </div>
                                <div className='row'>
                                    <div className='row email_label'>
                                        OTP
                                    </div>
                                    <div className='email_input'>
                                        <input type="text" className='login_input' onChange={(e) => setOtp(e.target.value)} value={otp} />
                                    </div>
                                </div>

                                <div className='row'>
                                    <button className='login_button' onClick={verifyForgotOtpHandler}>
                                        Submit OTP
                                    </button>
                                </div>
                            </>
                        )}

                        {otpVerified && (
                            <>
                                <div className='row login_text'>
                                    Choose a strong and secure password including at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character
                                </div>
                                <div className='row'>
                                    <div className='row email_label'>
                                        New Password
                                    </div>
                                    <div className='email_input'>
                                        <input type="password" className='login_input' onChange={(e) => setPassword(e.target.value)} value={password} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='row email_label'>
                                        Confirm Password
                                    </div>
                                    <div className='email_input'>
                                        <input type="password" className='login_input' onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
                                    </div>
                                </div>

                                <div className='row'>
                                    <button className='login_button' onClick={resetPasswordHandler}>
                                        Reset Password
                                    </button>
                                </div>
                            </>
                        )}

                        <div className='row'>
                            <p className='signup_text'>Have access to your account? <Link to="/login" className='signup_link'>Log In</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword