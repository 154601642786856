import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import forgotPasswordSlice from "./slices/forgotPasswordSlice";
import userSlice from "./slices/userSlice";
import accountSlice from "./slices/accountSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        forgotPassword: forgotPasswordSlice,
        user: userSlice,
        account: accountSlice,
    }
});

export default store