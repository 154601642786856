import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Login.css';
import { useDispatch, useSelector } from 'react-redux';
import { login, clearError } from '../redux/slices/authSlice';
import Navbar from './Navbar';

const Login = () => {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [innerError, setInnerError] = useState('');
  const [password, setPassword] = useState('');

  const submitLogin = () => {
    dispatch(clearError());
    setInnerError('');
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setInnerError('Please enter a valid email address');
      return;
    }
    if (!email) {
      setInnerError('Please enter your email');
      return;
    }
    if (!password.trim()) {
      setInnerError('Please enter your password');
      return;
    }
    dispatch(login({ email, password }));
  }

  return (
    <>
      <Navbar />
      <div className='container-fluid'>
        <div className='row d-flex justify-content-center align-items-center px-3'>
          <div className='col-lg-4'>
            <div className='row brand_div_login'>
              LOG IN
            </div>
            <div className='row login_text'>
              Log in to your account to check your progress and career. If you don't have an account, you can apply for internship.
            </div>
            <div className='row login_error'>
              {error && error.error}
              {innerError}
            </div>
            <div className='row'>
              <div className='row email_label'>
                Email
              </div>
              <div className='email_input'>
                <input type="text" className='login_input' onChange={(e) => setEmail(e.target.value)} value={email} />
              </div>
              <div className='row email_label'>
                Password
              </div>
              <div className='email_input'>
                <input type="password" className='login_input' onChange={(e) => setPassword(e.target.value)} value={password} />
              </div>
            </div>

            <div className='row forgot_password'>
              <p><span className='forgot_password_text'><Link to="/forgotpassword" className='forgot_password_link'>Forgot Password?</Link></span></p>
            </div>

            <div className='row'>
              <button className='login_button' onClick={submitLogin}>
                Log In
              </button>
            </div>

            {/* <div className='row'>
              <button className='google_button'>Log In with Google</button>
            </div> */}

            <div className='row'>
              <p className='signup_text'>Don't have an account? <Link to="/applynow" className='signup_link'>Apply Now</Link></p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
