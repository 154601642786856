import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../constants";

const initialState = {
    token: localStorage.getItem('token'),
    user: JSON.parse(localStorage.getItem('user')),
    isOtpSend: false,
    otpId: null,
    password: null,
    isVerified: localStorage.getItem('isVerified'),
    error: null,
    loading: false
};

export const clearError = createAsyncThunk('auth/clearError', async () => {
    return null;
});

export const login = createAsyncThunk('auth/login', async ({ email, password }, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/auth/login', { email, password });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const sendOtp = createAsyncThunk('auth/sendOtp', async ({ name, email }, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/auth/sendRegisterOtp', { name, email });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const verifyOtp = createAsyncThunk('auth/verifyOtp', async ({ otpId, otp, password }, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/auth/verifyOtp', { otpId, otp, password });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const uploadCv = createAsyncThunk('auth/uploadCv', async (fileData, { rejectWithValue, getState }) => {
    try {
        const formData = new FormData();
        formData.append('cv', fileData);

        const { token } = getState().auth;

        const response = await axios.post(`${API_URL}/auth/uploadCv`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

export const checkCv = createAsyncThunk('auth/checkCv', async (fileData, { rejectWithValue, getState }) => {
    try {
        const response = await axios.post(`${API_URL}/auth/checkCv`, fileData, {
            headers: {
                'Authorization': `Bearer ${getState().auth.token}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

export const wrongEmail = createAsyncThunk('auth/wrongEmail', async () => {
    return null;
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('isVerified');
            state.token = null;
            state.user = null;
            state.isVerified = false;
            state.error = null;
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(clearError.fulfilled, (state) => {
                state.error = null;
            })
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                localStorage.setItem('token', action.payload.token);
                localStorage.setItem('user', JSON.stringify(action.payload.user));
                localStorage.setItem('isVerified', action.payload.isVerified);
                state.token = action.payload.token;
                state.user = action.payload.user;
                state.isVerified = action.payload.isVerified;
                state.error = null;
                state.loading = false;
            })
            .addCase(login.rejected, (state, action) => {
                state.token = null;
                state.user = null;
                state.isVerified = false;
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(sendOtp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(sendOtp.fulfilled, (state, action) => {
                state.isOtpSend = true;
                state.otpId = action.payload.otpId;
                state.error = null;
                state.loading = false;
            })
            .addCase(sendOtp.rejected, (state, action) => {
                state.isOtpSend = false;
                state.otpId = null;
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(verifyOtp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                localStorage.setItem('token', action.payload.token);
                localStorage.setItem('user', JSON.stringify(action.payload.user));
                localStorage.setItem('isVerified', action.payload.isVerified);
                state.token = action.payload.token;
                state.user = action.payload.user;
                state.isVerified = action.payload.isVerified;
                state.isOtpSend = false;
                state.error = null;
                state.loading = false;
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.token = null;
                state.user = null;
                state.isVerified = false;
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(uploadCv.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(uploadCv.fulfilled, (state, action) => {
                localStorage.setItem('isVerified', action.payload.isVerified);
                state.isVerified = action.payload.isVerified;
                state.error = null;
                state.loading = false;
            })
            .addCase(uploadCv.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(checkCv.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(checkCv.fulfilled, (state, action) => {
                localStorage.setItem('isVerified', action.payload.isVerified);
                state.isVerified = action.payload.isVerified;
                state.error = null;
                state.loading = false;
            })
            .addCase(checkCv.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(wrongEmail.fulfilled, (state) => {
                state.error = null;
                state.isOtpSend = false;
                state.otpId = null;
            });
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
