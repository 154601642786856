import React, { useEffect, useState } from 'react';
import '../../styles/Account.css';
import { BiEditAlt } from "react-icons/bi";
import { getUser, updateUser, updateUserNameAndPhone, updatePhoto } from '../../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";
import { getSocialLinks, addSocialLink, updateSocialLink, deleteSocialLink, getEducation, addEducation, updateEducation, deleteEducation, getWorkExperience, addWorkExperience, updateWorkExperience, deleteWorkExperience } from '../../redux/slices/accountSlice';
import time from '../../time';
import { API_URL } from '../../constants';

const Account = () => {
    const dispatch = useDispatch();

    const { token } = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.user);
    const { social, education, workExperience } = useSelector((state) => state.account);

    const [showPhotoUpload, setShowPhotoUpload] = useState(false);
    const [showEditUserDetails, setShowEditUserDetails] = useState(false);
    const [showNameAndPhoneDetails, setShowNameAndPhoneDetails] = useState(false);

    const [selectedSocial, setSelectedSocial] = useState({});
    const [showAddSocialLinks, setShowAddSocialLinks] = useState(false);
    const [showEditSocialLinks, setShowEditSocialLinks] = useState(false);

    const [selectedEducation, setSelectedEducation] = useState({});
    const [showAddEducation, setShowAddEducation] = useState(false);
    const [showEditEducation, setShowEditEducation] = useState(false);

    const [selectedWorkExperience, setSelectedWorkExperience] = useState({});
    const [showAddWorkExperience, setShowAddWorkExperience] = useState(false);
    const [showEditWorkExperience, setShowEditWorkExperience] = useState(false);

    const [linkType, setLinkType] = useState('');
    const [link, setLink] = useState('');
    const [editLinkType, setEditLinkType] = useState('');
    const [editLink, setEditLink] = useState('');
    const [nameOfInstitute, setNameOfInstitute] = useState('');
    const [nameOfDegree, setNameOfDegree] = useState('');
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');
    const [editNameOfInstitute, setEditNameOfInstitute] = useState('');
    const [editNameOfDegree, setEditNameOfDegree] = useState('');
    const [editStartYear, setEditStartYear] = useState('');
    const [editEndYear, setEditEndYear] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [position, setPosition] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [editCompanyName, setEditCompanyName] = useState('');
    const [editPosition, setEditPosition] = useState('');
    const [editStartDate, setEditStartDate] = useState('');
    const [editEndDate, setEditEndDate] = useState('');
    const [editCity, setEditCity] = useState('');
    const [editState, setEditState] = useState('');
    const [editCountry, setEditCountry] = useState('');
    const [editAddress, setEditAddress] = useState('');
    const [editName, setEditName] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [profilePhoto, setProfilePhoto] = useState('');

    const addSocial = () => {
        dispatch(addSocialLink({ token, linkType, link }));
        setLinkType('');
        setLink('');
        setShowAddSocialLinks(false);
        dispatch(getSocialLinks({ token }));
    }

    const addEducationHandle = () => {
        dispatch(addEducation({ token, nameOfInstitute, nameOfDegree, startYear, endYear }));
        setNameOfInstitute('');
        setNameOfDegree('');
        setStartYear('');
        setEndYear('');
        setShowAddEducation(false);
    }

    const addWorkExperienceHandle = () => {
        dispatch(addWorkExperience({ token, companyName, position, startDate, endDate }));
        setcompanyName('');
        setPosition('');
        setStartDate('');
        setEndDate('');
        setShowAddWorkExperience(false);
    }

    const getSocialDetails = (id) => {
        setSelectedSocial(id);
        setShowEditSocialLinks(true);
        setEditLinkType(selectedSocial && selectedSocial.linkType);
        setEditLink(selectedSocial && selectedSocial.link);
    }

    const getEducationDetails = (id) => {
        setSelectedEducation(id);
        setShowEditEducation(true);
    }

    const getWorkExperienceDetails = (id) => {
        setSelectedWorkExperience(id);
        setShowEditWorkExperience(true);
    }

    const editSocial = () => {
        dispatch(updateSocialLink({ token, linkId: selectedSocial._id, linkType: editLinkType, link: editLink }));
        setEditLinkType('');
        setEditLink('');
        setShowEditSocialLinks(false);
    }

    const editEducationhandle = () => {
        dispatch(updateEducation({ token, educationId: selectedEducation._id, nameOfInstitute: editNameOfInstitute, nameOfDegree: editNameOfDegree, startYear: editStartYear, endYear: editEndYear }));
        setEditNameOfInstitute('');
        setEditNameOfDegree('');
        setEditStartYear('');
        setEditEndYear('');
        setShowEditEducation(false);
    }

    const editWorkExperiencehandle = () => {
        dispatch(updateWorkExperience({ token, workExperienceId: selectedWorkExperience._id, companyName: editCompanyName, position: editPosition, startDate: editStartDate, endDate: editEndDate }));
        setEditCompanyName('');
        setEditPosition('');
        setEditStartDate('');
        setEditEndDate('');
        setShowEditWorkExperience(false);
    }


    const deleteSocial = () => {
        dispatch(deleteSocialLink({ token, linkId: selectedSocial._id }));
        setEditLinkType('');
        setEditLink('');
        setShowEditSocialLinks(false);
    }

    const deleteEducationhandle = () => {
        dispatch(deleteEducation({ token, educationId: selectedEducation._id }));
        setSelectedEducation({});
        setEditNameOfInstitute('');
        setEditNameOfDegree('');
        setEditStartYear('');
        setEditEndYear('');
        setShowEditEducation(false);
    }

    const deleteWorkExperiencehandle = () => {
        dispatch(deleteWorkExperience({ token, workExperienceId: selectedWorkExperience._id }));
        setSelectedWorkExperience({});
        setEditCompanyName('');
        setEditPosition('');
        setEditStartDate('');
        setEditEndDate('');
        setShowEditWorkExperience(false);
    }

    const editUserDetailsHandle = () => {
        dispatch(updateUser({ token, city: editCity, state: editState, country: editCountry, address: editAddress }));
        setEditCity('');
        setEditState('');
        setEditCountry('');
        setEditAddress('');
        setShowEditUserDetails(false);
    }

    const updateUserNameAndPhoneHandle = () => {
        dispatch(updateUserNameAndPhone({ token, name: editName, phone: editPhone }));
        setEditName('');
        setEditPhone('');
        setShowNameAndPhoneDetails(false);
    }

    const updateProfilePhotoHandler = () => {
        dispatch(updatePhoto({ token, photo: profilePhoto }));
        setProfilePhoto('');
        setShowPhotoUpload(false);
    }

    useEffect(() => {
        if (selectedSocial) {
            setEditLinkType(selectedSocial.linkType || '');
            setEditLink(selectedSocial.link || '');
        }
    }, [selectedSocial]);

    useEffect(() => {
        if (selectedEducation) {
            setEditNameOfInstitute(selectedEducation.institute || '');
            setEditNameOfDegree(selectedEducation.degree || '');
            setEditStartYear(selectedEducation.startYear || '');
            setEditEndYear(selectedEducation.endYear || '');
        }
    }, [selectedEducation]);

    useEffect(() => {
        if (selectedWorkExperience) {
            setEditCompanyName(selectedWorkExperience.company || '');
            setEditPosition(selectedWorkExperience.position || '');
            setEditStartDate(selectedWorkExperience.startYear || '');
            setEditEndDate(selectedWorkExperience.endYear || '');
        }
    }, [selectedWorkExperience]);


    useEffect(() => {
        if (user) {
            setEditName(user.name || '');
            setEditPhone(user.phone || '');
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            setEditCity(user.city || '');
            setEditState(user.state || '');
            setEditCountry(user.country || '');
            setEditAddress(user.address || '');
        }
    }, [user]);

    useEffect(() => {
        dispatch(getUser({ token }));
        dispatch(getSocialLinks({ token }));
        dispatch(getEducation({ token }));
        dispatch(getWorkExperience({ token }));
    }, []);

    console.log(profilePhoto);
    return (
        <div className='container-fluid'>
            <div className='row account_row'>
                <div className='col'>
                    <div className='row normal_row_account'>
                        <div className='heading_section_account'>
                            <div className='your_profile'>
                                Your profile
                            </div>
                            <div className='joined_on'>
                                Joined {user && time(user.createdAt)}
                            </div>
                        </div>
                        <div className=''>
                            <img className='profile_img2' src={user ? {API_URL} + '/' + user.photo : '/blank_db.png'} data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setShowPhotoUpload(true)} />
                        </div>
                        <div className='name_div_profile'>
                            <div className='name_account'>
                                {user && user.name}
                            </div>
                            <div className='edit_name_account' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setShowNameAndPhoneDetails(true)}>
                                <BiEditAlt />
                                <san>Edit</san>
                            </div>
                        </div>
                        <div className='number_account'>
                            {user && user.phone || "No Phone Number"}
                        </div>
                    </div>

                    <div className='row normal_row_account'>
                        <div className='heading_section_account'>
                            <div className='your_profile'>
                                Basic Details
                            </div>
                            <div className='edit_name_account' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setShowEditUserDetails(true)}>
                                <BiEditAlt />
                                <san>Edit</san>
                            </div>
                        </div>
                        <div className='details_heading'>
                            <div>
                                <div className='key_account'>
                                    Email
                                </div>
                                <div className='value_account'>
                                    {user && user.email}
                                </div>
                            </div>
                        </div>
                        <div className='details_heading'>
                            <div>
                                <div className='key_account'>
                                    City
                                </div>
                                <div className='value_account'>
                                    {user && user.city || "--- --- ---"}
                                </div>
                            </div>
                        </div>
                        <div className='details_heading'>
                            <div>
                                <div className='key_account'>
                                    State
                                </div>
                                <div className='value_account'>
                                    {user && user.state || "--- --- ---"}
                                </div>
                            </div>
                        </div>
                        <div className='details_heading'>
                            <div>
                                <div className='key_account'>
                                    Country
                                </div>
                                <div className='value_account'>
                                    {user && user.country || "--- --- ---"}
                                </div>
                            </div>
                        </div>
                        <div className='details_heading'>
                            <div>
                                <div className='key_account'>
                                    Address
                                </div>
                                <div className='value_account'>
                                    {user && user.address || "--- --- ---"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className='row normal_row_account'>
                        <div className='heading_section_account'>
                            <div className='your_profile'>
                                Social Links <span className='add_span_text' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setShowAddSocialLinks(true)}>Add</span>
                            </div>
                        </div>
                        {social && social.map((item) => (
                            <div className='details_heading'>
                                <div>
                                    <div className='key_account'>
                                        {item.linkType}
                                    </div>
                                    <div className='value_account'>
                                        {item.link}
                                    </div>
                                </div>
                                <div className='edit_name_account' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => getSocialDetails(item)}>
                                    <BiEditAlt />
                                    <span>Edit</span>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='row normal_row_account'>
                        <div className='heading_section_account'>
                            <div className='your_profile'>
                                Education Details <span className='add_span_text' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setShowAddEducation(true)}>Add</span>
                            </div>
                        </div>
                        {education.map((item) => (
                            <div className='details_heading'>
                                <div>
                                    <div className='key_account'>
                                        {item.institute} - {item.endYear?.split('-')[0]}
                                    </div>
                                    <div className='value_account'>
                                        {item.degree}
                                    </div>
                                </div>
                                <div className='edit_name_account' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => getEducationDetails(item)}>
                                    <BiEditAlt />
                                    <span>Edit</span>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='row normal_row_account'>
                        <div className='heading_section_account'>
                            <div className='your_profile'>
                                Work Experiences <span className='add_span_text' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => setShowAddWorkExperience(true)}>Add</span>
                            </div>
                        </div>
                        {workExperience.map((item) => (
                            <div className='details_heading'>
                                <div>
                                    <div className='key_account'>
                                        {item.position} {item.startYear.split('-')[0]} - {item.endYear ? item.endYear?.split('-')[0] : "Present"}
                                    </div>
                                    <div className='value_account'>
                                        {item.company}
                                    </div>
                                </div>
                                <div className='edit_name_account' data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => getWorkExperienceDetails(item)}>
                                    <BiEditAlt />
                                    <span>Edit</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Modal for social */}
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    {showAddSocialLinks && (
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className='modal_heading'>
                                    <div className='modal_heading_text'>
                                        Add Social Links
                                    </div>
                                    <div className='modal_close_icon' data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowAddSocialLinks(false)}>
                                        <IoMdClose />
                                    </div>
                                </div>
                                <div className='modal_body'>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Link Type *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Facebook, Linkedin, Github' onChange={(e) => setLinkType(e.target.value)} value={linkType} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Link URL *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: https://www.linkedin.com/' onChange={(e) => setLink(e.target.value)} value={link} />
                                    </div>
                                </div>
                                <div className='modal_footer'>
                                    <button className="save_button_modal" data-bs-dismiss="modal" onClick={addSocial}>Save</button>
                                    <button className="close_button_modal" data-bs-dismiss="modal" onClick={() => setShowAddSocialLinks(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showEditSocialLinks && (
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className='modal_heading'>
                                    <div className='modal_heading_text'>
                                        Edit Social Links
                                    </div>
                                    <div className='modal_close_icon' data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowEditSocialLinks(false)}>
                                        <IoMdClose />
                                    </div>
                                </div>
                                <div className='modal_body'>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Link Type *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Facebook, Linkedin, Github' onChange={(e) => setEditLinkType(e.target.value)} value={editLinkType} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Link URL *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: https://www.linkedin.com/' onChange={(e) => setEditLink(e.target.value)} value={editLink} />
                                    </div>
                                </div>
                                <div className='modal_footer'>
                                    <button className="delete_button_modal" data-bs-dismiss="modal" onClick={deleteSocial}>Delete</button>
                                    <button className="save_button_modal" data-bs-dismiss="modal" onClick={editSocial}>Update</button>
                                    <button className="close_button_modal" data-bs-dismiss="modal" onClick={() => setShowEditSocialLinks(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showAddEducation && (
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className='modal_heading'>
                                    <div className='modal_heading_text'>
                                        Add Education Details
                                    </div>
                                    <div className='modal_close_icon' data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowAddEducation(false)}>
                                        <IoMdClose />
                                    </div>
                                </div>
                                <div className='modal_body'>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Name of Institute *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: IIT Kharagpur' onChange={(e) => setNameOfInstitute(e.target.value)} value={nameOfInstitute} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Name of Degree *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: B.Tech' onChange={(e) => setNameOfDegree(e.target.value)} value={nameOfDegree} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Start Date *</label>
                                        <input type="date" className='input_field_modal' onChange={(e) => setStartYear(e.target.value)} value={startYear} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>End Date *</label>
                                        <input type="date" className='input_field_modal' onChange={(e) => setEndYear(e.target.value)} value={endYear} />
                                    </div>
                                </div>
                                <div className='modal_footer'>
                                    <button className="save_button_modal" data-bs-dismiss="modal" onClick={addEducationHandle}>Save</button>
                                    <button className="close_button_modal" data-bs-dismiss="modal" onClick={() => setShowAddEducation(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showEditEducation && (
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className='modal_heading'>
                                    <div className='modal_heading_text'>
                                        Add Education Details
                                    </div>
                                    <div className='modal_close_icon' data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowAddEducation(false)}>
                                        <IoMdClose />
                                    </div>
                                </div>
                                <div className='modal_body'>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Name of Institute *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: IIT Kharagpur' onChange={(e) => setEditNameOfInstitute(e.target.value)} value={editNameOfInstitute} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Name of Degree *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: B.Tech' onChange={(e) => setEditNameOfDegree(e.target.value)} value={editNameOfDegree} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Start Date *</label>
                                        <input type="date" className='input_field_modal' onChange={(e) => setEditStartYear(e.target.value)} value={editStartYear} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>End Date *</label>
                                        <input type="date" className='input_field_modal' onChange={(e) => setEditEndYear(e.target.value)} value={editEndYear} />
                                    </div>
                                </div>
                                <div className='modal_footer'>
                                    <button className="delete_button_modal" data-bs-dismiss="modal" onClick={deleteEducationhandle}>Delete</button>
                                    <button className="save_button_modal" data-bs-dismiss="modal" onClick={editEducationhandle}>Update</button>
                                    <button className="close_button_modal" data-bs-dismiss="modal" onClick={() => setShowAddEducation(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showAddWorkExperience && (
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className='modal_heading'>
                                    <div className='modal_heading_text'>
                                        Add Work Experience
                                    </div>
                                    <div className='modal_close_icon' data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowAddWorkExperience(false)}>
                                        <IoMdClose />
                                    </div>
                                </div>
                                <div className='modal_body'>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Company Name *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Tata Consultancy Services' onChange={(e) => setcompanyName(e.target.value)} value={companyName} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Position *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Software Developer' onChange={(e) => setPosition(e.target.value)} value={position} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Start Date *</label>
                                        <input type="date" className='input_field_modal' onChange={(e) => setStartDate(e.target.value)} value={startDate} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>End Date</label>
                                        <input type="date" className='input_field_modal' onChange={(e) => setEndDate(e.target.value)} value={endDate} />
                                    </div>
                                </div>
                                <div className='modal_footer'>
                                    <button className="save_button_modal" data-bs-dismiss="modal" onClick={addWorkExperienceHandle}>Save</button>
                                    <button className="close_button_modal" data-bs-dismiss="modal" onClick={() => setShowAddWorkExperience(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showEditWorkExperience && (
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className='modal_heading'>
                                    <div className='modal_heading_text'>
                                        Edit Work Experience
                                    </div>
                                    <div className='modal_close_icon' data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowEditWorkExperience(false)}>
                                        <IoMdClose />
                                    </div>
                                </div>
                                <div className='modal_body'>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Company Name *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Tata Consultancy Services' onChange={(e) => setEditCompanyName(e.target.value)} value={editCompanyName} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Position *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Software Developer' onChange={(e) => setEditPosition(e.target.value)} value={editPosition} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Start Date *</label>
                                        <input type="date" className='input_field_modal' onChange={(e) => setEditStartDate(e.target.value)} value={editStartDate} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>End Date</label>
                                        <input type="date" className='input_field_modal' onChange={(e) => setEditEndDate(e.target.value)} value={editEndDate} />
                                    </div>
                                </div>
                                <div className='modal_footer'>
                                    <button className="delete_button_modal" data-bs-dismiss="modal" onClick={deleteWorkExperiencehandle}>Delete</button>
                                    <button className="save_button_modal" data-bs-dismiss="modal" onClick={editWorkExperiencehandle}>Update</button>
                                    <button className="close_button_modal" data-bs-dismiss="modal" onClick={() => setShowEditWorkExperience(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showEditUserDetails && (
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className='modal_heading'>
                                    <div className='modal_heading_text'>
                                        Edit Details
                                    </div>
                                    <div className='modal_close_icon' data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowEditUserDetails(false)}>
                                        <IoMdClose />
                                    </div>
                                </div>
                                <div className='modal_body'>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>City *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Mumbai' onChange={(e) => setEditCity(e.target.value)} value={editCity} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>State *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Maharastra' onChange={(e) => setEditState(e.target.value)} value={editState} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Country *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: India' onChange={(e) => setEditCountry(e.target.value)} value={editCountry} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Address *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Plot No: 322, 3rd Floor, Sector 18, Dwarka, New Delhi' onChange={(e) => setEditAddress(e.target.value)} value={editAddress} />
                                    </div>
                                </div>
                                <div className='modal_footer'>
                                    <button className="save_button_modal" data-bs-dismiss="modal" onClick={editUserDetailsHandle}>Update</button>
                                    <button className="close_button_modal" data-bs-dismiss="modal" onClick={() => setShowEditUserDetails(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showNameAndPhoneDetails && (
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className='modal_heading'>
                                    <div className='modal_heading_text'>
                                        Edit Profile Details
                                    </div>
                                    <div className='modal_close_icon' data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowNameAndPhoneDetails(false)}>
                                        <IoMdClose />
                                    </div>
                                </div>
                                <div className='modal_body'>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Name *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: Andros John' onChange={(e) => setEditName(e.target.value)} value={editName} />
                                    </div>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Phone *</label>
                                        <input type="text" className='input_field_modal' placeholder='Ex: 6566677634' onChange={(e) => setEditPhone(e.target.value)} value={editPhone} />
                                    </div>
                                </div>
                                <div className='modal_footer'>
                                    <button className="save_button_modal" data-bs-dismiss="modal" onClick={updateUserNameAndPhoneHandle}>Update</button>
                                    <button className="close_button_modal" data-bs-dismiss="modal" onClick={() => setShowNameAndPhoneDetails(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {showPhotoUpload && (
                        <div class="modal-content">
                            <div class="modal-body">
                                <div className='modal_heading'>
                                    <div className='modal_heading_text'>
                                        Edit Profile Photo
                                    </div>
                                    <div className='modal_close_icon' data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowPhotoUpload(false)}>
                                        <IoMdClose />
                                    </div>
                                </div>
                                <div className='modal_body'>
                                    <div className='input_group'>
                                        <label className='input_label_modal'>Upload Photo *</label>
                                        <input type="file" name="profilePhoto" accept="image/*" className='input_field_modal' onChange={(e) => setProfilePhoto(e.target.files[0])} />
                                    </div>
                                </div>
                                <div className='modal_footer'>
                                    <button className="save_button_modal" data-bs-dismiss="modal" onClick={updateProfilePhotoHandler}>Update</button>
                                    <button className="close_button_modal" data-bs-dismiss="modal" onClick={() => setShowPhotoUpload(false)}>Close</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Account