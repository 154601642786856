import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './components/dash_screens/Dashboard';
import Login from './components/Login';
import Apply from './components/Apply';
import OtpCheck from './components/OtpCheck';
import UploadCv from './components/UploadCv';
import ForgotPassword from './components/ForgotPassword';
import { useSelector } from 'react-redux';

function App() {
  const { token, isOtpSend, isVerified } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {token === null ? (
            <>
              <Route exact path="*" element={<Login />} />
              <Route exact path="/" element={<Login />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/applynow" element={isOtpSend ? <OtpCheck /> : <Apply />} />
              <Route exact path="/forgotpassword" element={<ForgotPassword />} />
            </>
          ) : (
            <>
              {isVerified === 'none' ? (
                <>
                  <Route exact path="*" element={<UploadCv />} />
                  <Route exact path="/" element={<UploadCv />} />
                  <Route exact path="/login" element={<UploadCv />} />
                  <Route exact path="/applynow" element={<UploadCv />} />
                  <Route exact path="/forgotpassword" element={<UploadCv />} />
                </>
              ) : (
                <>
                  {isVerified === true ? (
                    <>
                      <Route exact path="*" element={<Dashboard />} />
                      <Route exact path="/" element={<Dashboard />} />
                      <Route exact path="/dashboard/*" element={<Dashboard />} />
                    </>
                  ) : (
                    <>
                      <Route exact path="*" element={<UploadCv />} />
                      <Route exact path="/" element={<UploadCv />} />
                      <Route exact path="/login" element={<UploadCv />} />
                      <Route exact path="/applynow" element={<UploadCv />} />
                      <Route exact path="/forgotpassword" element={<UploadCv />} />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
