import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../constants";

const initialState = {
    stage1: true,
    forgotOtpSend: false,
    otpId: null,
    otpVerified: false,
    resetDone: false,
    error: null,
    loading: false
};

export const clearError = createAsyncThunk('forgotPassword/clearError', async () => {
    return null;
});

export const sendForgotOtp = createAsyncThunk('forgotPassword/sendForgotOtp', async ({ email }, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/auth/sendForgotPasswordOtp', { email });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const verifyForgotOtp = createAsyncThunk('forgotPassword/verifyForgotOtp', async ({ otpId, otp }, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/auth/verifyForgotPasswordOtp', { otpId, otp });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const resetPassword = createAsyncThunk('forgotPassword/resetPassword', async ({ otpId, password }, { rejectWithValue }) => {
    try {
        const response = await axios.post(API_URL + '/auth/resetPassword', { otpId, password });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(clearError.fulfilled, (state) => {
                state.error = null;
            })
            
            .addCase(sendForgotOtp.pending, (state) => {
                state.resetDone = false;
                state.loading = true;
                state.error = null;
            })
            .addCase(sendForgotOtp.fulfilled, (state, action) => {
                state.stage1 = false;
                state.forgotOtpSend = true;
                state.otpId = action.payload.otpId;
                state.loading = false;
                state.error = null;
            })
            .addCase(sendForgotOtp.rejected, (state, action) => {
                state.forgotOtpSend = false;
                state.otpId = null;
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(verifyForgotOtp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(verifyForgotOtp.fulfilled, (state, action) => {
                state.forgotOtpSend = false;
                state.otpVerified = true;
                state.loading = false;
                state.error = null;
            })
            .addCase(verifyForgotOtp.rejected, (state, action) => {
                state.otpVerified = false;
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.stage1 = true;
                state.forgotOtpSend = false;
                state.otpId = null;
                state.otpVerified = false;
                state.resetDone = true;
                state.error = null;
                state.loading = false;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
});

export default forgotPasswordSlice.reducer;
