import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtp, clearError } from '../redux/slices/authSlice';
import Navbar from './Navbar';

const Apply = () => {
    const dispatch = useDispatch();
    const { error } = useSelector((state) => state.auth);
    
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [innerError, setInnerError] = useState("");

    const continueApply = () => {
        dispatch(clearError());
        setInnerError("");
        if (!fullName.trim()) {
            setInnerError("Please enter your full name");
            return;
        }

        if (!email.trim()) {
            setInnerError("Please enter your email");
            return;
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setInnerError("Please enter a valid email");
            return;
        }
        dispatch(sendOtp({ name: fullName, email: email }));

        setFullName("");
        setEmail("");
    }

    return (
        <>
        <Navbar />
            <div className='container-fluid'>
                <div className='row d-flex justify-content-center align-items-center px-3'>
                    <div className='col-lg-4'>
                        <div className='row brand_div_login'>
                            APPLY NOW
                        </div>
                        <div className='row login_text'>
                            Apply now for the internship Program. After this please check your email for further instructions.
                        </div>
                        <div className='row login_error'>
                            {error && error.error}
                            {innerError}
                        </div>
                        <div className='row'>
                            <div className='row email_label'>
                                Full Name
                            </div>
                            <div className='email_input'>
                                <input type="text" className='login_input' onChange={(e) => setFullName(e.target.value)} value={fullName} />
                            </div>
                            <div className='row email_label'>
                                Email
                            </div>
                            <div className='email_input'>
                                <input type="text" className='login_input' onChange={(e) => setEmail(e.target.value)} value={email} />
                            </div>
                        </div>

                        <div className='row'>
                            <button className='login_button' onClick={continueApply}>
                                Continue
                            </button>
                        </div>

                        {/* <div className='row'>
                            <button className='google_button'>Continue In with Google</button>
                        </div> */}

                        <div className='row'>
                            <p className='signup_text'>Already have an account? <Link to="/login" className='signup_link'>Log In Now</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Apply
