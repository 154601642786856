import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MainDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/dashboard") {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div>
      Main Dashboard
    </div>
  )
}

export default MainDashboard
