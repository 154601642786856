import React from 'react';
import "../../styles/Dashboard.css";
import { Link, Route, Routes } from 'react-router-dom';
import { MdSpaceDashboard, MdMessage, MdOutlineHelp } from "react-icons/md";
import { GiProgression } from "react-icons/gi";
import { IoNotifications } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
import { HiAcademicCap } from "react-icons/hi";
import UpperBar from '../UpperBar';
import MainDashboard from './MainDashboard';
import Career from './Career';
import Progress from './Progress';
import Help from './Help';
import Messages from './Messages';
import Notification from './Notification';
import Account from './Account';
import { logout } from '../../redux/slices/authSlice';
import { useDispatch } from 'react-redux';

const data = [
    {
        logo: <MdSpaceDashboard />,
        name: "Dashboard",
        link: "/dashboard",
    },
    {
        logo: <HiAcademicCap />,
        name: "Career",
        link: "/dashboard/career",
    },
    {
        logo: <GiProgression />,
        name: "Progress",
        link: "/dashboard/progress",
    },
    {
        logo: <MdMessage />,
        name: "Messages",
        link: "/dashboard/messages",
    },
    {
        logo: <IoNotifications />,
        name: "Notification",
        link: "/dashboard/notification",
    },
    {
        logo: <FaUser />,
        name: "Account",
        link: "/dashboard/account",
    },
    {
        logo: <MdOutlineHelp />,
        name: "Help",
        link: "/dashboard/help",
    },
]

const Dashboard = () => {
    const dispatch = useDispatch();

    return (
        <>
            <div className='dashboard_container'>
                <div className='sidebar_container'>
                    <div className='container-fluid'>
                        <div className='row top_sidebar'>
                            <div className='row brand_name_464'>
                                <img src='multiscaler_full_logo copy.png' className='brand_img' />
                            </div>
                        </div>

                        <div className='row middle_sidebar'>
                            <div className='menu_bar'>
                                <div>
                                    {data.map((menu, index) => (
                                        <div className='menu_div_outer' key={index}>
                                            <Link to={menu.link}>
                                                <div className='manu_div'>
                                                    <div className='menu_logo'>{menu.logo}</div>
                                                    <div className='menu_name'>{menu.name}</div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className='row bottom_sidebar'>
                            <div className='logout_menu' onClick={() => dispatch(logout())}>
                                <div className='logout_text'>
                                    Logout
                                </div>
                                <div className='logo_logout'>
                                    <TbLogout />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main_container'>
                    <UpperBar />
                    <Routes>
                        <Route path='/' element={<MainDashboard />} />
                        <Route path='/career' element={<Career />} />
                        <Route path='/progress' element={<Progress />} />
                        <Route path='/messages' element={<Messages />} />
                        <Route path='/notification' element={<Notification />} />
                        <Route path='/account' element={<Account />} />
                        <Route path='/help' element={<Help />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default Dashboard
